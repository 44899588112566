import FailedToGet from '@components/failed-to-get/FailedToGet';
import CompanyService from '@services/CompanyService';
import { ICompany } from '@shared/interfaces/ICompany';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import RemoveButton from './components/remove-button/RemoveButton';
import styles from './ProductCompaniesTable.module.css';

type ProductCompaniesTableProps = {
  id: string;
};

const ProductCompaniesTable = ({ id }: ProductCompaniesTableProps): JSX.Element => {
  const companyService = new CompanyService();

  // RQ Query to get companies under given product id
  const {
    isLoading: isGetCompaniesLoading,
    isError: isGetCompaniesError,
    error: getCompaniesError,
    data: companies,
  } = companyService.listCompaniesByProductId(id);

  if (isGetCompaniesError) {
    return <FailedToGet label='companies' reason={getCompaniesError.message} />;
  }

  const companyNameTemplate = (companyData: ICompany): JSX.Element => (
    <div className='flex justify-content-between align-items-center'>
      <span>{companyData.companyName}</span>
    </div>
  );

  const sustainableAlternativeAvailableTemplate = (companyData: ICompany): JSX.Element | null =>
    companyData.sustainableAlternativeAvailable ? (
      <span>Sustainable alternative available</span>
    ) : null;

  const removeButtonTemplate = (companyData: ICompany): JSX.Element => (
    <div className='flex justify-content-end align-items-center'>
      <RemoveButton productId={id} companyId={companyData.id} />
    </div>
  );

  return (
    <div className='p-4'>
      <DataTable
        scrollable
        sortField='companyId'
        sortOrder={1}
        scrollHeight='50vh'
        size='small'
        value={companies}
        loading={isGetCompaniesLoading}
        className={styles['product-companies-table']}
      >
        <Column field='companyId' header='Company ID' sortable className='w-2' />
        <Column field='bpn' header='BPN' sortable className='w-2' />
        <Column header='Company Name' sortable body={companyNameTemplate} />
        <Column body={sustainableAlternativeAvailableTemplate} />
        <Column body={removeButtonTemplate} style={{ width: '100px' }} />
      </DataTable>
    </div>
  );
};

export default ProductCompaniesTable;
