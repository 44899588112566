import { IRequest } from '@shared/interfaces/IRequest';
import { ColumnSortEvent } from 'primereact/column';

export const requestProductNameSortFunction = (event: ColumnSortEvent): IRequest[] => {
  const data = [...event.data] as IRequest[];

  const eventOrder = event.order || 1;

  data.sort((data1, data2) => {
    const productName1 = data1?.product?.productName;
    const productName2 = data2?.product?.productName;

    let result = 1;

    // Using == instead of === to check for null or undefined
    if (productName1 == null && productName2 == null) result = 0;
    else if (productName1 == null && productName2 != null) result = -1;
    else if (typeof productName1 === 'string' && typeof productName2 === 'string')
      return productName1.localeCompare(productName2) * eventOrder;

    return eventOrder * result;
  });

  return data;
};
