/* eslint-disable camelcase */

import FailedToGet from '@components/failed-to-get/FailedToGet';
import ProductService from '@services/ProductService';
import { productRegionSortFunction } from '@shared/helpers/product-region-sort-function';
import { IProduct } from '@shared/interfaces/IProduct';
import { Column } from 'primereact/column';
import {
  DataTable,
  DataTableExpandedRows,
  DataTableRowEvent,
  DataTableValueArray,
} from 'primereact/datatable';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';

import ProductCompaniesTable from '../product-companies-table/ProductCompaniesTable';
import ProductsTableHeader from './components/products-table-header/ProductsTableHeader';

const ProductsTable = (): JSX.Element => {
  const [expandedRows, setExpandedRows] = useState<
    DataTableExpandedRows | DataTableValueArray | undefined
  >(undefined);

  const [companyId, setCompanyId] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [od, setOd] = useState<string>('');

  // Debounced search values
  const [debouncedSearch] = useDebounce(search, 500);

  const productService = new ProductService();

  // RQ Query to get products
  const {
    isLoading: isGetProductsLoading,
    isFetching: isProductsFetching,
    isError: isGetProductsError,
    error: getProductsError,
    data: products,
    refetch: refetchProducts,
  } = productService.listProducts({
    companyId: companyId,
    search: debouncedSearch,
    od: od,
  });

  const onRowToggle = (event: DataTableRowEvent): void => {
    setExpandedRows(event.data);
  };

  const rowExpansionTemplate = (data: IProduct): JSX.Element => (
    <ProductCompaniesTable id={data.productId} />
  );

  if (isGetProductsError) {
    return <FailedToGet label='companies' reason={getProductsError.message} />;
  }

  return (
    <div className='w-full'>
      <DataTable
        value={products}
        showGridlines
        sortField='productCid'
        sortOrder={1}
        size='small'
        header={
          <ProductsTableHeader
            refetchProducts={refetchProducts}
            isProductsFetching={isProductsFetching}
            companyId={companyId}
            setCompanyId={setCompanyId}
            search={search}
            setSearch={setSearch}
            od={od}
            setOd={setOd}
          />
        }
        loading={isGetProductsLoading}
        expandedRows={expandedRows}
        onRowToggle={onRowToggle}
        rowExpansionTemplate={rowExpansionTemplate}
      >
        <Column expander className='w-3rem' />
        <Column field='productCid' sortable header='Product ID' className='w-2' />
        <Column field='productName' sortable header='Product Name' />
        <Column
          field='region'
          sortable
          sortField='region'
          sortFunction={productRegionSortFunction}
          header='Region'
          className='w-2'
        />
        <Column field='od' sortable header='OD' className='w-2' showFilterMenu={false} />
      </DataTable>
    </div>
  );
};

export default ProductsTable;
/* eslint-disable camelcase */
