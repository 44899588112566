import { RequestType } from '@shared/enums/RequestType';
import { IRequest } from '@shared/interfaces/IRequest';
import { ColumnSortEvent } from 'primereact/column';

export const requestProductIdSortFunction = (event: ColumnSortEvent): IRequest[] => {
  const data = [...event.data] as IRequest[];

  const eventOrder = event.order || 1;

  data.sort((data1, data2) => {
    let productId1;
    let productId2;

    if (data1.requestType === RequestType.additional_component) {
      productId1 = data1.otherRequestData?.component_id;
    } else {
      productId1 = data1.product?.productId;
    }

    if (data2.requestType === RequestType.additional_component) {
      productId2 = data2.otherRequestData?.component_id;
    } else {
      productId2 = data2.product?.productId;
    }

    let result = 1;

    // Using == instead of === to check for null or undefined
    if (productId1 == null && productId2 == null) result = 0;
    else if (productId1 == null && productId2 != null) result = -1;
    else if (typeof productId1 === 'string' && typeof productId2 === 'string')
      return productId1.localeCompare(productId2) * eventOrder;

    return eventOrder * result;
  });

  return data;
};
