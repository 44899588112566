import CompanyService from '@services/CompanyService';
import { ICompany } from '@shared/interfaces/ICompany';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Dispatch, SetStateAction, useState } from 'react';

import styles from './CompanyFilter.module.css';

type DropdownOption = {
  label: string;
  value: string;
};

type CompanyFilterProps = {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
};

const CompanyFilter = ({ value, setValue }: CompanyFilterProps): JSX.Element => {
  const companyService = new CompanyService();

  const [selectedCompany, setSelectedCompany] = useState<ICompany | undefined>();

  // Fetch companies
  const { data: companies } = companyService.listCompanies();

  const onChange = (e: DropdownChangeEvent): void => {
    // Grab company object from React Query's data, by using the name coming from dropdown
    const _company = companies?.find((company) => company.companyName === e.value);

    setSelectedCompany(_company);

    setValue(_company?.id || '');
  };

  const dropdownOptions: DropdownOption[] =
    companies?.map((company) => ({
      label: company.companyName,
      value: company.companyName,
    })) ?? [];

  const valueTemplate = (option: DropdownOption): JSX.Element => {
    if (option?.label) {
      return (
        <>
          <span className='text-primary'>Company:</span> <span>{option?.label}</span>
        </>
      );
    }
    return <span>Company</span>;
  };

  const itemTemplate = (option: DropdownOption): JSX.Element => <span>{option.label}</span>;

  return (
    <div className={styles['dropdown']}>
      <Dropdown
        className={classNames(
          {
            'min-w-12rem max-w-20rem': selectedCompany,
            'md:w-12rem': !selectedCompany,
          },
          'w-full',
        )}
        options={dropdownOptions}
        value={(value ? selectedCompany?.companyName : value) || null}
        placeholder='Company'
        valueTemplate={valueTemplate}
        itemTemplate={itemTemplate}
        onChange={onChange}
        showClear
        filter
        filterInputAutoFocus
        pt={{
          input: {
            className: 'text-900',
          },
        }}
      />
    </div>
  );
};

export default CompanyFilter;
