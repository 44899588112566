import { RequestType } from '@shared/enums/RequestType';
import { removeUndefinedFields } from '@shared/helpers/remove-undefined-fields';
import IAmplifyApiConfig from '@shared/interfaces/IAmplifyApiConfig';
import { IRequest } from '@shared/interfaces/IRequest';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import AmplifyApiService from './AmplifyApiService';
import ImportFileService from './ImportFileService';

export type RequestFilter = {
  companyId?: string;
  requestSource?: string;
  requestedBy?: string;
  componentId?: string;
};

type answerPcfRequestProps = {
  requestId: number;
  selectedProductId: number;
  requestType: RequestType;
};

const BASE_PATH = '/requests';

class RequestService {
  private amplifyApiService: AmplifyApiService;
  private importFileService: ImportFileService;

  constructor() {
    this.amplifyApiService = new AmplifyApiService();
    this.importFileService = new ImportFileService();
  }

  listRequests(filter: RequestFilter = {}): UseQueryResult<IRequest[], Error> {
    // Clear undefined values from filter
    const _filter = removeUndefinedFields(filter);

    const amplifyApiConfig: IAmplifyApiConfig = {
      apiName: 'PacificApi',
      path: BASE_PATH,
      init: {
        queryStringParameters: { ..._filter },
      },
    };

    const query = useQuery<IRequest[], Error>({
      queryKey: [BASE_PATH, _filter],
      queryFn: async (): Promise<IRequest[]> => {
        const response = await this.amplifyApiService.get<IRequest[]>({
          config: amplifyApiConfig,
        });

        return response;
      },
    });

    return query;
  }

  answerPcfRequest(
    options: UseMutationOptions<void, Error, answerPcfRequestProps>,
  ): UseMutationResult<void, Error, answerPcfRequestProps> {
    return useMutation<void, Error, answerPcfRequestProps>({
      mutationFn: async ({ requestId, selectedProductId, requestType }: answerPcfRequestProps) => {
        // Determine action: answer external or internal request, based on request type
        let answerType;
        if (requestType === RequestType.external_pcf) answerType = 'answer-external-request';
        else answerType = 'answer-internal-new-non-regional-request';

        const amplifyApiConfig: IAmplifyApiConfig = {
          apiName: 'PacificApi',
          path: `${BASE_PATH}/${requestId}/${answerType}`,
          init: {},
        };

        const response = await this.amplifyApiService.put<{ componentId: number }, void>({
          config: amplifyApiConfig,
          body: {
            componentId: selectedProductId,
          },
        });

        return response;
      },
      ...options,
    });
  }
}

export default RequestService;
