import {
  RequestRegion,
  RequestRegionMap,
  RequestRegionType,
} from '@shared/enums/RequestRegionType';
import { IRequest } from '@shared/interfaces/IRequest';
import { ColumnSortEvent } from 'primereact/column';

export const requestRegionSortFunction = (event: ColumnSortEvent): IRequest[] => {
  const data = [...event.data] as IRequest[];

  const eventOrder = event.order || 1;

  data.sort((data1, data2) => {
    const regionType1 = data1.regionType;
    const regionType2 = data2.regionType;

    let region1 = RequestRegionMap[data1.region as RequestRegion];
    let region2 = RequestRegionMap[data2.region as RequestRegion];

    if (regionType1 === RequestRegionType.Global) region1 = 'Global';

    if (regionType2 === RequestRegionType.Global) region2 = 'Global';

    let result = 1;

    // Using == instead of === to check for null or undefined
    if (region1 == null && region2 == null) result = 0;
    else if (region1 == null && region2 != null) result = -1;
    else if (typeof region1 === 'string' && typeof region2 === 'string')
      return region1.localeCompare(region2) * eventOrder;

    return eventOrder * result;
  });

  return data;
};
