import ImportFileButton from '@components/import-file-button/ImportFileButton';
import useNotificationContext from '@hooks/useNotificationContext';
import CompanyService from '@services/CompanyService';
import SessionStorageService from '@services/SessionStorageService';
import { SupportedFileTypes } from '@shared/constants';
import { ToastSeverity } from '@shared/enums/ToastSeverity';
import { useQueryClient } from '@tanstack/react-query';
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload';
import { useEffect, useRef, useState } from 'react';

type ImportCompaniesProps = {
  showText?: boolean;
};

const ImportCompanies = ({ showText = true }: ImportCompaniesProps): JSX.Element => {
  const queryClient = useQueryClient();

  const { notify } = useNotificationContext();

  const companyService = new CompanyService();
  const sessionStorageService = new SessionStorageService();

  const fileUploadRef = useRef<FileUpload>(null);

  const [isProcessing, setIsProcessing] = useState<boolean>(
    sessionStorageService.getIsImportingCompanies(),
  );

  const onMutate = (): void => {
    notify({
      severity: ToastSeverity.INFO,
      summary: 'Import started...',
      detail: 'You will receive a notification once the import is complete.',
      life: 5000,
    });

    sessionStorageService.setIsImportingCompanies();
  };

  // RQ Mutation for importing companies
  const { isLoading, mutate: importCompanies } = companyService.importCompanies({
    onMutate,
    onError: () => {
      notify({
        severity: ToastSeverity.ERROR,
        summary: 'Something went wrong!',
        detail: 'Please try again later.',
        life: 5000,
      });

      sessionStorageService.deleteIsImportingCompanies();
    },
  });

  const uploadHandler = (e: FileUploadHandlerEvent): void => {
    const file = e.files[0];
    const fileFormat = file.name.split('.').pop() as string;

    if (!SupportedFileTypes.includes(fileFormat)) {
      notify({
        severity: ToastSeverity.ERROR,
        summary: 'Invalid file format',
        detail: `Supported formats: ${SupportedFileTypes.map((fileType) => '.' + fileType).join(
          ', ',
        )}`,
        life: 5000,
      });

      fileUploadRef.current?.clear();

      return;
    }

    importCompanies({ file });
    setIsProcessing(true);
  };

  const handleFinishedImportingCompaniesEvent = (): void => {
    queryClient.invalidateQueries({ queryKey: ['/companies'] });

    fileUploadRef.current?.clear();
    setIsProcessing(false);
  };

  useEffect(() => {
    // When a message is received from the websocket for importing companies,
    //  the 'finishedImportingCompanies' event is dispatched
    // That means the import and processing are completed
    window.addEventListener('finishedImportingCompanies', handleFinishedImportingCompaniesEvent);

    return () =>
      window.removeEventListener(
        'finishedImportingCompanies',
        handleFinishedImportingCompaniesEvent,
      );
  }, []);

  return (
    <div className='grid grid-nogutter w-full'>
      <div className='col-4 lg:col-2 align-content-center'>
        <ImportFileButton
          name='importCompanies'
          label='Import Companies'
          disabled={isProcessing || isLoading}
          uploadHandler={uploadHandler}
          fileUploadRef={fileUploadRef}
        />
      </div>
      {showText && (
        <div className='col-8 lg:col-10 pl-2'>
          <p className='m-0'>Please import an Excel file with a list of companies.</p>

          <div className='m-0 flex flex-row'>
            <span className='white-space-nowrap'>Take a look at our</span>
            <a
              href={
                process.env.REACT_APP_S3_CONTENT_URL +
                '/sample-uploads/Pacific+Sample+Companies.xlsx'
              }
              download='Pacific Sample Companies.xlsx'
              target='_blank'
              rel='noreferrer'
              className='text-primary mx-1 underline'
            >
              sample
            </a>
            <span className='white-space-nowrap'>Excel file.</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImportCompanies;
