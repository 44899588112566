/**
 * Mapping between License Type and backend value returned
 */
export enum LicenseType {
  BASIC = 'basic',
  PRO = 'pro',
  AUTOMOTIVE = 'automotive',
}

export type LicenseHistoryItem = {
  id: number;
  companyId: number;
  previousLicense: LicenseType | null;
  license: LicenseType;
  issuedBy: number;
  dateCreated: string;
  email: string;
};

export const mapLicenseTypeToLabel = (license: LicenseType): string => {
  switch (license) {
    case LicenseType.BASIC:
      return 'Basic';
    case LicenseType.PRO:
      return 'Pro';
    case LicenseType.AUTOMOTIVE:
      return 'Automotive';
    default:
      return 'Unknown';
  }
};

export interface ICompany {
  id: string;
  bpn?: string;
  companyId: string;
  companyName: string;
  dateUpdated?: Date;
  dateCreated?: Date;
  edcConfigurationStatus?: EdcConfigurationStatus;
  edcBaseUrl?: string;
  license: LicenseType;
  sustainableAlternativeAvailable?: boolean;
}

export enum EdcConfigurationStatus {
  Pending = 'pending',
  FirstStepCompleted = 'first_step_completed',
  Completed = 'completed',
}

export interface ICompanyEDCConfiguration {
  cognitoClientId: string;
  cognitoClientSecret: string;
  edcBaseUrl: string;
  edcClientId: string;
  edcClientSecret: string;
  pcfExchangeClientSecretKey: string;
  dtrManagementUrl: string;
  dtrClientId: string;
  dtrClientSecret: string;
}
