import { IRequest } from '@shared/interfaces/IRequest';
import { ColumnSortEvent } from 'primereact/column';

export const requestRequestSourceSortFunction = (event: ColumnSortEvent): IRequest[] => {
  const data = [...event.data] as IRequest[];

  const eventOrder = event.order || 1;

  data.sort((data1, data2) => {
    const requestSource1 = data1.requestSource;
    const requestSource2 = data2.requestSource;

    let result = 1;

    // Using == instead of === to check for null or undefined
    if (requestSource1 == null && requestSource2 == null) result = 0;
    else if (requestSource1 == null && requestSource2 != null) result = -1;
    else if (typeof requestSource1 === 'string' && typeof requestSource2 === 'string')
      return requestSource1.localeCompare(requestSource2) * eventOrder;

    return eventOrder * result;
  });

  return data;
};
