import { ICompany } from '@shared/interfaces/ICompany';
import { Button } from 'primereact/button';

type ConfigureEDCConnectionButtonProps = {
  data: ICompany;
  onClick: () => void;
};

function ConfigureEDCConnectionButton(
  props: ConfigureEDCConnectionButtonProps,
): JSX.Element | null {
  if (!props?.data?.bpn) {
    return null;
  }

  return (
    <Button text onClick={props.onClick}>
      Configure EDC & DTR Connections
    </Button>
  );
}

export default ConfigureEDCConnectionButton;
