import { removeUndefinedFields } from '@shared/helpers/remove-undefined-fields';
import IAmplifyApiConfig from '@shared/interfaces/IAmplifyApiConfig';
import { FileImportType } from '@shared/interfaces/INotification';
import { IUser } from '@shared/interfaces/IUser';
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';

import AmplifyApiService from './AmplifyApiService';
import ImportFileService from './ImportFileService';

export type UserFilter = {
  companyId?: string;
};

type UploadUsersProps = {
  file: File;
};

type ResendInviteProps = {
  userId: string;
};

type RQMutationSideEffectProps = {
  onMutate?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
};

const BASE_PATH = '/users';

class UserService {
  private amplifyApiService: AmplifyApiService;
  private importFileService: ImportFileService;

  constructor() {
    this.amplifyApiService = new AmplifyApiService();
    this.importFileService = new ImportFileService();
  }

  listUsers(filter: UserFilter = {}): UseQueryResult<IUser[], Error> {
    const _filter = removeUndefinedFields(filter);

    const amplifyApiConfig: IAmplifyApiConfig = {
      apiName: 'PacificApi',
      path: BASE_PATH,
      init: {
        queryStringParameters: { ..._filter },
      },
    };

    const query = useQuery<IUser[], Error>({
      queryKey: [BASE_PATH, _filter],
      queryFn: async (): Promise<IUser[]> => {
        const response = await this.amplifyApiService.get<IUser[]>({
          config: amplifyApiConfig,
        });

        return response;
      },
    });

    return query;
  }

  importUsers({
    onMutate,
    onSuccess,
    onError,
  }: RQMutationSideEffectProps): UseMutationResult<void, Error, UploadUsersProps> {
    const mutation = useMutation<void, Error, UploadUsersProps>({
      mutationFn: async ({ file }: UploadUsersProps) => {
        const filename = file.name;

        const signedUrl = await this.importFileService.getSignedUrl({
          filename,
          type: FileImportType.IMPORT_USERS,
        });

        await this.importFileService.uploadFile({
          file,
          signedUrl,
        });
      },
      onMutate,
      onSuccess,
      onError,
    });

    return mutation;
  }

  resendInvite({
    onMutate,
    onSuccess,
    onError,
  }: RQMutationSideEffectProps): UseMutationResult<void, Error, ResendInviteProps> {
    const mutation = useMutation<void, Error, ResendInviteProps>({
      mutationFn: async ({ userId }: ResendInviteProps) => {
        const amplifyApiConfig: IAmplifyApiConfig = {
          apiName: 'PacificApi',
          path: `${BASE_PATH}/${userId}/resend-invite`,
          init: {},
        };

        await this.amplifyApiService.put<undefined, undefined>({
          config: amplifyApiConfig,
          body: undefined,
        });
      },
      onMutate,
      onSuccess,
      onError,
    });

    return mutation;
  }
}

export default UserService;
