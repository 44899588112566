import ImportFileButton from '@components/import-file-button/ImportFileButton';
import useNotificationContext from '@hooks/useNotificationContext';
import SessionStorageService from '@services/SessionStorageService';
import UserService from '@services/UserService';
import { SupportedFileTypes } from '@shared/constants';
import { ToastSeverity } from '@shared/enums/ToastSeverity';
import { useQueryClient } from '@tanstack/react-query';
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload';
import { useEffect, useRef, useState } from 'react';

const ImportUsers = (): JSX.Element => {
  const queryClient = useQueryClient();

  const { notify } = useNotificationContext();

  const userService = new UserService();
  const sessionStorageService = new SessionStorageService();

  const fileUploadRef = useRef<FileUpload>(null);

  const [isProcessing, setIsProcessing] = useState<boolean>(
    sessionStorageService.getIsImportingUsers(),
  );

  const onMutate = (): void => {
    notify({
      severity: ToastSeverity.INFO,
      summary: 'Import started...',
      detail: 'You will receive a notification once the import is complete.',
      life: 5000,
    });

    sessionStorageService.setIsImportingUsers();
  };

  // RQ Mutation for importing users
  const { isLoading, mutate: importUsers } = userService.importUsers({
    onMutate,
  });

  const uploadHandler = (e: FileUploadHandlerEvent): void => {
    const file = e.files[0];
    const fileFormat = file.name.split('.').pop() as string;

    if (!SupportedFileTypes.includes(fileFormat)) {
      notify({
        severity: ToastSeverity.ERROR,
        summary: 'Invalid file format',
        detail: `Supported formats: ${SupportedFileTypes.map((fileType) => '.' + fileType).join(
          ', ',
        )}`,
        life: 5000,
      });

      fileUploadRef.current?.clear();

      return;
    }

    importUsers({ file });
    setIsProcessing(true);
  };

  const handleFinishedImportingUsersEvent = (): void => {
    queryClient.invalidateQueries({ queryKey: ['/users'] });

    fileUploadRef.current?.clear();
    setIsProcessing(false);
  };

  useEffect(() => {
    // When a message is received from the websocket for importing users,
    //  the 'finishedImportingUsers' event is dispatched
    // That means the import and processing are completed
    window.addEventListener('finishedImportingUsers', handleFinishedImportingUsersEvent);

    return () =>
      window.removeEventListener('finishedImportingUsers', handleFinishedImportingUsersEvent);
  }, []);

  return (
    <div className='grid grid-nogutter w-full'>
      <div className='col-4 lg:col-2 align-content-center'>
        <ImportFileButton
          label='Import Users'
          name='importUsers'
          disabled={isProcessing || isLoading}
          uploadHandler={uploadHandler}
          fileUploadRef={fileUploadRef}
        />
      </div>

      <div className='col-8 lg:col-10 pl-2'>
        <p className='m-0'>Please import an Excel file with a list of users.</p>

        <div className='m-0 flex flex-row'>
          <span className='white-space-nowrap'>Take a look at our</span>
          <a
            href={
              process.env.REACT_APP_S3_CONTENT_URL + '/sample-uploads/Pacific+Sample+Users.xlsx'
            }
            download='Pacific Sample Users.xlsx'
            target='_blank'
            rel='noreferrer'
            className='text-primary mx-1 underline'
          >
            sample
          </a>
          <span className='white-space-nowrap'>Excel file.</span>
        </div>
      </div>
    </div>
  );
};

export default ImportUsers;
