import useDialogContext from '@hooks/useDialogContext';
import RequestService from '@services/RequestService';
import { RequestStatus } from '@shared/enums/RequestStatus';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';

interface RemoveButtonProps {
  productId: string;
  companyId: string;
}

// Action type, which will be used to determine the dialog content, e.g. title, text, buttons, etc.
export enum RemoveButtonAction {
  REMOVE,
  DECLINE,
  EXPIRE,
  DECLINED,
  EXPIRED,
}

const RemoveButton = ({ productId, companyId }: RemoveButtonProps): JSX.Element => {
  const [action, setAction] = useState<RemoveButtonAction>(RemoveButtonAction.REMOVE);

  const { openDialog } = useDialogContext();

  const requestService = new RequestService();

  // RQ Query to get requests
  // When removing a product-company assignment, we need to check requests
  // A confirmation dialog will show up and its content will be based on the requests
  // 1. If there are no requests with the same productId & companyId combination, show a simple confirmation dialog
  // 2. If there is a pending request with the same productId & companyId, show a confirmation dialog for declining the request
  // 3. If there is a completed request with the same productId & companyId, show a confirmation dialog for expiring the request

  const { data: requests, isLoading } = requestService.listRequests({
    // eslint-disable-next-line camelcase
    componentId: productId,
  });

  useEffect(() => {
    // When requests are loaded, find if there is any request with the same productId & companyId
    // And determine action of the remove button
    if (!requests || requests.length === 0) return;

    const existingRequest = requests.find(
      (request) => request?.product?.id === productId && request?.company?.id === companyId,
    );

    if (existingRequest) {
      if (existingRequest.status === RequestStatus.pending) {
        setAction(RemoveButtonAction.DECLINE);
      } else if (existingRequest.status === RequestStatus.complete) {
        setAction(RemoveButtonAction.EXPIRE);
      } else if (existingRequest.status === RequestStatus.rejected) {
        setAction(RemoveButtonAction.DECLINED);
      } else if (existingRequest.status === RequestStatus.outdated) {
        setAction(RemoveButtonAction.EXPIRED);
      }
    }
  }, [requests]);

  const onClick = (): void => {
    openDialog('remove-product-company-dialog', { action, productId, companyId });
  };

  if (isLoading) {
    return (
      <div>
        <i className='pi pi-spin pi-spinner' style={{ color: 'var(--text-color)' }} />
      </div>
    );
  }

  return (
    <div>
      {action === RemoveButtonAction.REMOVE && (
        <Button rounded text style={{ minWidth: 'unset', padding: '0.5rem' }} onClick={onClick}>
          <i className='pi pi-times' style={{ color: 'var(--text-color)' }} />
        </Button>
      )}

      {action === RemoveButtonAction.DECLINE && (
        <Button outlined style={{ minWidth: 'unset', padding: '0.5rem' }} onClick={onClick}>
          Decline
        </Button>
      )}
      {action === RemoveButtonAction.DECLINED && (
        <div style={{ color: 'var(--gray-600)' }}>Declined</div>
      )}
      {action === RemoveButtonAction.EXPIRED && (
        <div style={{ color: 'var(--gray-600)' }}>Withdrawn</div>
      )}
      {action === RemoveButtonAction.EXPIRE && (
        <Button outlined style={{ minWidth: 'unset', padding: '0.5rem' }} onClick={onClick}>
          Withdraw
        </Button>
      )}
    </div>
  );
};

export default RemoveButton;
