import { removeDuplicatedProducts } from '@shared/helpers/remove-duplicated-products';
import { removeUndefinedFields } from '@shared/helpers/remove-undefined-fields';
import IAmplifyApiConfig from '@shared/interfaces/IAmplifyApiConfig';
import { FileImportType } from '@shared/interfaces/INotification';
import { IProduct } from '@shared/interfaces/IProduct';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import AmplifyApiService from './AmplifyApiService';
import ImportFileService from './ImportFileService';

export type ProductFilter = {
  status?: string;
  region?: string;
  companyId?: string;
  productCid?: string;
  hasRegion?: boolean;
  od?: string;
  search?: string; // productName and/or productId (not cid)
};

type UploadProductsProps = {
  file: File;
};

type RQMutationSideEffectProps = {
  onMutate?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
};

type ExportProductsResponse = {
  fileUrl: string;
};

export type UpdatePcfValuesResponse = {
  outdatedRequestCount: number;
  updatedRequestCount: number;
};

const BASE_PATH = '/components';

class ProductService {
  private amplifyApiService: AmplifyApiService;
  private importFileService: ImportFileService;

  constructor() {
    this.amplifyApiService = new AmplifyApiService();
    this.importFileService = new ImportFileService();
  }

  listProducts(
    filter: ProductFilter = {},
    options: UseQueryOptions<IProduct[], Error> = {},
  ): UseQueryResult<IProduct[], Error> {
    // Clear undefined values from filter
    const _filter = removeUndefinedFields(filter);

    const amplifyApiConfig: IAmplifyApiConfig = {
      apiName: 'PacificApi',
      path: BASE_PATH,
      init: {
        queryStringParameters: { ..._filter },
      },
    };

    const query = useQuery<IProduct[], Error>({
      queryKey: [BASE_PATH, _filter],
      queryFn: async (): Promise<IProduct[]> => {
        const response = await this.amplifyApiService.get<IProduct[]>({
          config: amplifyApiConfig,
        });

        return removeDuplicatedProducts(response);
      },
      ...options,
    });

    return query;
  }

  importProducts({
    onMutate,
    onSuccess,
    onError,
  }: RQMutationSideEffectProps): UseMutationResult<void, Error, UploadProductsProps> {
    const mutation = useMutation<void, Error, UploadProductsProps>({
      mutationFn: async ({ file }: UploadProductsProps) => {
        const filename = file.name;

        const signedUrl = await this.importFileService.getSignedUrl({
          filename,
          type: FileImportType.IMPORT_PRODUCTS,
        });

        await this.importFileService.uploadFile({
          file,
          signedUrl,
        });
      },
      onMutate,
      onSuccess,
      onError,
    });

    return mutation;
  }

  exportProducts(
    options: UseMutationOptions<ExportProductsResponse, Error, void> = {},
  ): UseMutationResult<ExportProductsResponse, Error, void> {
    const mutation = useMutation<ExportProductsResponse, Error, void>({
      mutationFn: async (): Promise<ExportProductsResponse> => {
        const amplifyApiConfig: IAmplifyApiConfig = {
          apiName: 'PacificApi',
          path: `${BASE_PATH}/export-components`,
          init: {},
        };

        const result = await this.amplifyApiService.post<undefined, ExportProductsResponse>({
          config: amplifyApiConfig,
          body: undefined,
        });

        return result;
      },

      ...options,
    });

    return mutation;
  }

  updatePcfValues(
    options: UseMutationOptions<UpdatePcfValuesResponse, Error, void> = {},
  ): UseMutationResult<UpdatePcfValuesResponse, Error, void> {
    const mutation = useMutation<UpdatePcfValuesResponse, Error, void>({
      mutationFn: async (): Promise<UpdatePcfValuesResponse> => {
        const amplifyApiConfig: IAmplifyApiConfig = {
          apiName: 'PacificApi',
          path: `${BASE_PATH}/update-pcf-values`,
          init: {},
        };

        const result = await this.amplifyApiService.post<undefined, UpdatePcfValuesResponse>({
          config: amplifyApiConfig,
          body: undefined,
        });

        return result;
      },
      ...options,
    });

    return mutation;
  }
}

export default ProductService;
